<template lang="pug">
  div(ref="swiper2").additional-services__slider.swiper
    .swiper-wrapper
      .swiper-slide.additional-services__item
        h4.additional-services__subtitle
          | ПЕРЕОБЛАДНАННЯ РОУТЕРУ STARLINK
        .additional-services__cost
          | 120
          span $
        p
          | Швидке та якісне переобладнання вашого роутера від Starlink під 12V живлення.
        router-link(to="", v-scroll-to="'#steps5'").link.additional-services__link Замовити
      .swiper-slide.additional-services__item
        h4.additional-services__subtitle
          | РЕМОНТ STARLINK
        .additional-services__cost
          span ВІД
          | 65
          span $
        p
          | Професійне обслуговування та ремонт вашого обладнання Starlink.
        router-link(to="", v-scroll-to="'#steps5'").link.additional-services__link Замовити
      .swiper-slide.additional-services__item.price__item--decor
        h4.additional-services__subtitle
          | ЗАМІНА РОЗ'ЄМІВ НА RJ45
        .additional-services__cost
          span ВІД
          | 70
          span $
        p
          | Заміна стандартних роз'ємів вашого обладнання Starlink на універсальні RJ45.
        router-link(to="", v-scroll-to="'#steps5'").link.additional-services__link Замовити
      .swiper-slide.additional-services__item.additional-services__item--active
        h4.additional-services__subtitle
          | ІНДИВІДУАЛЬНІ РІШЕННЯ
        .additional-services__cost
          span ВІД
          | 60
          span $
        p Адаптація обладнання Starlink до ваших унікальних потреб.
        router-link(to="", v-scroll-to="'#steps5'").link.additional-services__link Замовити
    .swiper-button-prev .
    .swiper-pagination .
    .swiper-button-next .
</template>

<script>
// swiper
import Swiper, { Navigation, Pagination } from 'swiper'
import 'swiper/swiper-bundle.min.css'

export default {
  name: 'PriceSlider',
  mounted () {
    new Swiper(this.$refs.swiper2, {
      modules: [Navigation, Pagination],
      slidesPerView: 3,
      slidesPerGroup: 1,
      spaceBetween: 24,
      loop: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      },
      breakpoints: {
        250: {
          slidesPerView: 1
        },
        550: {
          slidesPerView: 2
        },
        992: {
          slidesPerView: 3
        }
      }
    })
  }
}
</script>
